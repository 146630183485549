<template>
  <div class="index_wrap" >
    <div class="address_list" >
      <div class="address_item" v-for="(item,index) in list" :key="index" @click="toChoose(item)" >
        <div class="address_top" >
          <div class="user_wrap" >
            <div class="username" >{{ item.realName }}</div>
            <div class="phone_text" >{{ item.phone }}</div>
          </div>
          <div class="address_text" >{{ item.province }}{{ item.city }}{{ item.district }}{{ item.detail }}</div>
        </div>
        <div class="address_bottom" >
          <div class="default_wrap" @click.stop="changeDefault(item.userAddressId, 0)" >
            <img src="./img/1.png" class="checked_icon" v-if="item.isDefault == 1" />
            <img src="./img/2.png" class="checked_icon" v-else />
            默认地址
          </div>
          <div class="address_panel" >
            <div class="edit_button" @click.stop="update(item.userAddressId)" >编辑</div>
            <div class="del_button" @click.stop="handleDel(item.userAddressId)" >删除</div>
          </div>
        </div>
      </div>

      <van-empty description="暂无收货地址" v-if="list.length <= 0" />

<!--      <div class="address_item" >
        <div class="address_top" >
          <div class="user_wrap" >
            <div class="username" >王女士</div>
            <div class="phone_text" >1509990000</div>
          </div>
          <div class="address_text" >这里是地址这里是地址这里是地址这里是地</div>
        </div>
        <div class="address_bottom" >
          <div class="default_wrap" >
            <img src="./img/2.png" class="checked_icon" />
            默认地址
          </div>
          <div class="address_panel" >
            <div class="edit_button" >编辑</div>
            <div class="del_button" >删除</div>
          </div>
        </div>
      </div>-->

    </div>
    <div class="add_button" @click="toPage('addAddress')" >新增地址</div>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getUserAddressVoListByPage,
  deleteUserAddress,
  updateUserAddressDefault
} from '@/api'
import { Dialog, Toast } from 'vant'
export default {
  name: 'address',
  data() {
    return {
      page: 1,
      pageSize: 10,
      list: [],
      type: this.$route.query.type
    }
  },
  methods: {
    update(userAddressId) {
      this.$router.push({
        name: 'addAddress',
        query: {
          userAddressId
        }
      })
    },
    changeDefault(userAddressId, type) {
      updateUserAddressDefault({ userAddressId }).then(res => {
        if(res.success) {
          if(type == 1){
            this.$router.go(-1)
            return false
          }
          Toast('操作成功')
          this.reSearch()
        }else {
          Toast(res.msg)
        }
      })
    },
    handleDel(userAddressId) {
      Dialog.confirm({
        title: '提示',
        message: '是否删除地址',
        confirmButtonColor: '#03CFAF',
      })
        .then(() => {
          // on confirm
          deleteUserAddress({ userAddressId }).then(res => {
            if(res.success) {
              Toast('地址删除成功')
              this.reSearch()
            }
          })
        })
        .catch(() => {
          // on cancel
        });
    },
    reSearch() {
      this.page = 1
      this.list = []
      this.getUserAddressVoListByPage()
    },
    toPage(name) {
      this.$router.push({
        name
      })
    },
    toChoose(item) {
      if(!this.type) {
        return false
      }
      this.changeDefault(item.userAddressId, 1)
    },
    getUserAddressVoListByPage() {
      const { page, pageSize } = this
      getUserAddressVoListByPage({  }).then(res => {
        console.log(res)
        if(res.success) {
          this.list = this.list.concat(res.data)
        }
      })
    }
  },
  mounted() {
    this.reSearch()
  }
}
</script>
